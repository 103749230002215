import React, { Component } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Axios from 'axios';
import PropTypes from 'prop-types';
import TextField from 'components/ui-components-v2/TextField';
import Request from 'components/data/Request';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Loader from 'components/ui-components/Loader';
import '../styles/main.scss';

/**
 * Facebook Search
 * This displays the facebook search results
 */
export default class FacebookSearch extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** This is a disabled object */
        disabled: PropTypes.bool,
        /** Feed type */
        searchType: PropTypes.string,
        /** Class type */
        searchClass: PropTypes.string
    };

    static defaultProps = {
        disabled: false,
        searchType: 'adinterest',
        searchClass: null,
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : []
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    componentDidMount() {
        // Fetch a token to connect to the publish api
        Request.post('publishing/getToken', { type: 'metadata' }).then((result) => {
            if (!result.success) {
                SnackbarUtils.error("We coulnd't connect you to the Facebook API.");
                return;
            }
            this.token = result.data.token;
        });
    }

    /**
     * Change input, this starts a timeout for fetching the data
     * @param {*} event
     */
    handleSearch = (event) => {
        this.searchTerm = event.target.value;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.requestList, 400);
    };

    /**
     * Request the result list from Facebook
     */
    requestList = () => {
        if (this.searchTerm === '') {
            this.setState({ searchResults: [] });
            return;
        }
        this.setState({ loading: true });

        Axios.post(process.env.PUBLISH_ENGINE + 'V2/metadata/facebookmarketing/search', {
            token: this.token,
            q: this.searchTerm,
            type: this.props.searchType,
            class: this.props.searchClass
        }).then((response) => {
            if (response.data.data && response.data.data.data) {
                let dataSet = response.data.data.data;
                if (this.props.searchClass) {
                    dataSet = dataSet.filter((item) => {
                        return item.name.toLowerCase().includes(this.searchTerm.toLowerCase());
                    });
                }
                this.setState({ searchResults: dataSet, loading: false });
            } else {
                this.setState({ loading: false });
            }
        });
    };

    /**
     * Add an item
     * @param {*} item
     */
    handleClick = (item) => {
        const value = this.state.value;
        value.push(item);
        this.setState({ value, searchResults: [] });
        this.props.onMutation(value);
    };

    /**
     * Handle remove
     * @param {*} index
     */
    handleRemove = (index) => {
        const value = this.state.value;
        value.splice(index, 1);
        this.setState({ value, searchResults: [] });
        this.props.onMutation(value);
    };

    updateData = () => {
        this.props.onMutation(this.state.value);
    };

    render() {
        const { value = [], searchResults, loading = false } = this.state;

        return (
            <div className="input__facebook-search">
                <div className="input__facebook-search__input">
                    <TextField onChange={this.handleSearch} fullWidth margin="none" variant="outlined" />
                    {loading && <Loader />}
                </div>
                {searchResults && searchResults.map && (
                    <div className="input__facebook-search__results">
                        {searchResults &&
                            searchResults.map((item, i) => (
                                <div key={`${item.id}${i}`} className="input__facebook-search__results__item" onClick={() => this.handleClick(item)}>
                                    <div className="input__facebook-search__results__item__name">{item.name}</div>
                                    <div className="input__facebook-search__results__item__type">{item.topic ? item.topic : item.type}</div>
                                </div>
                            ))}
                    </div>
                )}

                {value && value.length > 0 && (
                    <div className="input__facebook-search__list">
                        <div className="input__facebook-search__list__title">Selected items:</div>

                        {value &&
                            value.map((item, i) => (
                                <div key={`${item.id}${i}`} className="input__facebook-search__list__item">
                                    <div className="input__facebook-search__list__item__name">
                                        {item.name} <span className="input__facebook-search__list__item__id">{`(${item.id})`}</span>
                                        <div className="input__facebook-search__results__item__type">{item.topic ? item.topic : item.type}</div>
                                    </div>

                                    <div className="input__facebook-search__list__item__remove" onClick={() => this.handleRemove(i)}>
                                        <IconButton size="small">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        );
    }
}
