import '../styles/main.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NativeSelect from '@mui/material/NativeSelect';

/**
 * Facebook Age
 * This shows a age selector
 */
export default class FacebookAge extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func
    };

    static defaultProps = {
        onMutation: () => {}
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : { min: 18, max: 65 }
        };
    }

    /**
     * Value changed
     * Send to onMutation
     */
    handleChange = (event, field) => {
        const value = this.state.value;
        value[field] = event.target.value;

        this.setState({ value });
        this.props.onMutation(value);
    };

    render() {
        const { value } = this.state;

        const options = [];
        for (let i = 18; i <= 65; i++) {
            options.push(
                <option value={i} key={'option' + i}>
                    {i}
                </option>
            );
        }

        return (
            <div className="input__facebook-age">
                <div className="input__facebook-age__min">
                    <NativeSelect value={value.min} label="Min Age" onChange={(event) => this.handleChange(event, 'min')}>
                        {options}
                    </NativeSelect>
                </div>
                <div className="input__facebook-age__max">
                    <NativeSelect value={value.max} label="Max Age" onChange={(event) => this.handleChange(event, 'max')}>
                        {options}
                    </NativeSelect>
                </div>
            </div>
        );
    }
}
