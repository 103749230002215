import React, { Component } from 'react';

import '../styles/main.scss';

/**
 * Divider
 * Adds a line between fields
 */
export default class Divider extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="input__divider" data-input-key={this.props.data.inputKey}>
                <div className="input__divider__line" />
            </div>
        );
    }
}
