import React, { ReactNode, useState } from 'react';
import { ListItemIcon, ListItemText } from '@mui/material';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Menu from 'components/ui-components-v2/Menu';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/main.scss';

interface Props {
    actions: FieldSetAction[];
}

export interface FieldSetAction {
    key: string;
    label: string;
    icon?: string;
    onClick: () => void;
}

const FieldSetActions: React.FC<Props> = ({ actions }) => {
    if (!actions) return null;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openOverwriteOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeOverwriteOptions = () => {
        setAnchorEl(null);
    };

    const getMenuItems = () => {
        const menuItems: ReactNode[] = [];

        actions.forEach((action) => {
            menuItems.push(
                <MenuItem
                    key={action.key}
                    onClick={() => {
                        action.onClick();
                        closeOverwriteOptions();
                    }}>
                    {action.icon && (
                        <ListItemIcon>
                            <Icon>{action.icon}</Icon>
                        </ListItemIcon>
                    )}
                    <ListItemText>{action.label}</ListItemText>
                </MenuItem>
            );
        });
        return menuItems;
    };

    return (
        <>
            <Icon className="input-field-set__label-container__icon-actions" onClick={openOverwriteOptions}>
                more_vert
            </Icon>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeOverwriteOptions}>
                {getMenuItems()}
            </Menu>
        </>
    );
};

export { FieldSetActions };
