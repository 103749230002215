import React from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@mui/material/ListItemText';
import Select from 'components/ui-components-v2/Select';
import MenuItem from 'components/ui-components-v2/MenuItem';
import { FeedRequest } from 'components/data/FeedManagementHelpers';
import User from 'components/data/User';
import '../styles/main.scss';

class FeedSelect extends React.Component {
    static propTypes = {
        onMutation: PropTypes.func
    };

    static defaultProps = {
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value.id : '',
            options: []
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && this.state.value === prevProps.value) {
            this.setState({ value: this.props.value.id });
        }
    }

    componentDidMount() {
        this.getFeeds();
    }

    getFeeds = () => {
        FeedRequest.get('dataset')
            .then((data) => {
                if (data && data.data) {
                    const list = data.data;
                    const options = [];

                    list.forEach((dataSet) => {
                        options.push({
                            title: dataSet.customData && dataSet.customData.title,
                            id: dataSet._id,
                            numberOfRows: dataSet.metadata && dataSet.metadata.itemCount
                        });
                    });

                    this.setState({ options });
                }
            })
            .catch((error) => {
                console.error('Error when getting datasets', error);
            });
    };

    handleChange = (e) => {
        const { onMutation } = this.props;
        const { options } = this.state;

        const id = e.target.value;

        this.setState({ value: id });

        const targetDataset = options.find((o) => o.id === id);

        if (targetDataset) {
            onMutation({
                id: targetDataset.id,
                title: targetDataset.title
            });
        }
    };

    render() {
        const { value, options } = this.state;

        if (!User.hasRight('feedManagement')) {
            let displayValue = 'No dataset selected';

            try {
                if (value) {
                    const targetDataset = options.find((o) => o.id === value);
                    displayValue = targetDataset.title;
                }
            } catch (err) {}

            return <React.Fragment>{displayValue}</React.Fragment>;
        }

        return (
            <Select
                margin="dense"
                fullWidth
                variant="outlined"
                className="feed-select"
                defaultValue=""
                onChange={this.handleChange}
                classes={{
                    selectMenu: 'feed-select__select-menu'
                }}
                value={value ? value : ''}>
                {(!options || options.length === 0) && (
                    <MenuItem value="">
                        <ListItemText primary="No datasets available" />
                    </MenuItem>
                )}
                {options &&
                    options.length > 0 &&
                    options.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            <ListItemText primary={option.title} secondary={`${option.numberOfRows} entries`} />
                        </MenuItem>
                    ))}
            </Select>
        );
    }
}

export default FeedSelect;
