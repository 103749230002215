import EditorData from 'components/editor-data/EditorData';

export class DynamicDataHelper {
    /**
     * Gets the real value
     * @param value
     * @param variables
     * @returns real value
     */
    public static getDynamicValue(value: string, variables = {}): string {
        value = value.replace('%[', '');
        value = value.replace(']%', '');

        const campaign = EditorData.get();

        if (!value.length) return '';

        // const valueParts = value.split('+');
        const valueParts = this.customSplitOfString(value);
        let result = '';
        for (const valuePart of valueParts) {
            const parsedValuePart = this.parseDynamicValuePart(valuePart, { ...variables, campaign });
            result = result + parsedValuePart;
        }

        return result;
    }

    /**
     * Parses part of the dynamic value
     * @param part
     * @param variables
     * @returns parsed part of dynamic value
     */
    private static parseDynamicValuePart = (part: string, variables: any): string => {
        // Try the eval, and if failed, return the part not parsed
        try {
            // Exclude parsing the lodash
            if (part === '_') return part;

            // Check if the value is from a feed row
            if (part.match(/item\.[a-zA-Z0-9_]+/) || part.match(/item\[(?:"|')[a-zA-Z0-9_]+(?:"|')\]/)) return `{{${part}}}`;
            const value = this.evaluateExpression(variables, part);

            if (!value) return part;
            return value;
        } catch (e) {
            return part;
        }
    };

    // Function that takes the variables object and evaluates the expression
    static evaluateExpression = (vars, expr) => {
        const func = new Function(...Object.keys(vars), `return ${expr};`);
        return func(...Object.values(vars));
    };

    /**
     * Custom split of string
     * @param value
     * @returns parts of string
     */
    static customSplitOfString = (value: string): string[] => {
        const result: string[] = [];
        let currentPart = '';
        const quoteChars = ["'", '`', '"'];
        let insideQuotes = false;
        let currentQuoteChar = '';

        for (let i = 0; i < value.length; i++) {
            const char = value[i];
            // Executes on start/end of quoted string in the value
            if (quoteChars.includes(char) && (!insideQuotes || currentQuoteChar === char)) {
                insideQuotes = !insideQuotes;
                if (!insideQuotes) currentQuoteChar = '';
                else currentQuoteChar = char;
                continue;
            }

            // Splits the parts
            if (char === '+' && !insideQuotes && (!currentQuoteChar.length || char === currentQuoteChar)) {
                result.push(currentPart);
                currentPart = '';
            } else {
                currentPart += char;
            }
        }

        if (currentPart) {
            result.push(currentPart);
        }
        return result;
    };

    /**
     * Custom delete of value
     * @param value
     * @returns string
     */
    static handleDeleteValueByIndex = (value: string, index: number): string => {
        let currentPart = '';
        const quoteChars = ["'", '`', '"'];
        let insideQuotes = false;
        let currentQuoteChar = '';
        let newValue = '';
        let currentIndex = 0;

        for (let i = 0; i < value.length; i++) {
            const char = value[i];

            // Checks if it is in quote and what type of quote is
            if (quoteChars.includes(char) && (!insideQuotes || currentQuoteChar === char)) {
                insideQuotes = !insideQuotes;
                if (!insideQuotes) currentQuoteChar = '';
                else currentQuoteChar = char;
            }

            // Checks if the string needs to be added to the result
            if (char === '+' && !insideQuotes && (!currentQuoteChar.length || char === currentQuoteChar)) {
                // Don't add the part if it that is the index to delete
                if (currentIndex !== index) {
                    // Checks if adding plus is needed
                    if (newValue.length) newValue = newValue + '+' + currentPart;
                    else newValue = newValue + currentPart;
                }
                currentPart = '';
                currentIndex++;
            } else {
                currentPart += char;
            }
        }

        if (currentPart && currentIndex !== index) {
            if (newValue.length) newValue = newValue + '+' + currentPart;
            else newValue = newValue + currentPart;
        }

        return newValue;
    };
}
