import '../styles/main.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Axios from 'axios';
import classNames from 'classnames';
import Request from 'components/data/Request';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import PublishHelpers from 'components/data/PublishHelpers';
import Loader from 'components/ui-components/Loader';
import AlertBox from '../../AlertBox';

/**
 * Facebook Custom Event
 * This displays a selector for a custom Facebook Event
 */
export default class FacebookCustomEvent extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** This is a disabled object */
        disabled: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        onMutation: () => {}
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : { id: 0, pixel: {} },
            loading: true
        };
    }

    componentDidMount() {
        // Fetch a token to connect to the publish api
        Request.post('publishing/getToken', { type: 'metadata' }).then((result) => {
            if (!result.success) {
                SnackbarUtils.error("We coulnd't connect you to the Facebook API.");
                return;
            }
            this.token = result.data.token;
            this.requestList();
        });
    }

    componentDidUpdate() {
        const { value = {} } = this.state;
        const pixelId = PublishHelpers.getFacebookPixelId();
        if (value && value.pixel && value.pixel.id && value.pixel.id != pixelId) {
            this.setState({ value: { id: 0, pixel: {} } });
            this.props.onMutation(null);
        }
    }

    /**
     * Request the result list from Facebook
     */
    requestList = () => {
        Axios.post(process.env.PUBLISH_ENGINE + 'V2/metadata/facebookmarketing/customEvents', {
            token: this.token,
            adAccountId: PublishHelpers.getFacebookAdAccountId()
        }).then((response) => {
            if (response.data.data && response.data.data.data) {
                let list = response.data.data.data;
                list = [{ id: 0, name: 'None selected' }, ...list];
                this.setState({ results: list, loading: false });
            } else {
                this.setState({ loading: false });
            }
        });
    };

    /**
     * Set value
     * @param {*} item
     */
    handleClick = (item) => {
        this.setState({ value: item });
        this.props.onMutation(item);
    };

    render() {
        const { value = {}, results, loading } = this.state;
        const pixelId = PublishHelpers.getFacebookPixelId();

        if (loading) {
            return <Loader />;
        }
        if (!loading && !results) {
            return <AlertBox alertType="warning" message="No custom events were found." />;
        }

        return (
            <div className="input__facebook-custom-event">
                {results && results.map && (
                    <div className="input__facebook-custom-event__results">
                        {results &&
                            results.map((item, i) =>
                                !item.pixel || item.pixel.id == pixelId ? (
                                    <div
                                        className={classNames('input__facebook-custom-event__results__item', {
                                            'input__facebook-custom-event__results__item--active': item.id == value.id
                                        })}
                                        onClick={() => this.handleClick(item)}
                                        key={i}>
                                        <div className="input__facebook-custom-event__results__item__name">{item.name}</div>
                                        {item.id ? <div className="input__facebook-custom-event__results__item__id">id: {item.id}</div> : ''}
                                        <div className="input__facebook-custom-event__results__item__type">{item.custom_event_type}</div>
                                    </div>
                                ) : (
                                    ''
                                )
                            )}
                    </div>
                )}
            </div>
        );
    }
}
