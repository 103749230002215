import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DOMPurify from 'dompurify';

/**
 * Display field
 */
export default class Display extends Component {
    static propTypes = {
        displayValue: PropTypes.any
    };

    static defaultProps = {
        displayValue: ''
    };

    render() {
        const { displayValue } = this.props;
        // Check if displayvalue is react component
        if (displayValue && displayValue.$$typeof) {
            return displayValue;
        }
        return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayValue) }} />;
    }
}
