import '../styles/main.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'components/ui-components-v2/Select';

/**
 * Facebook Location
 * This displays the field for finding the Facebook locations for advertising on a location specific base.
 */
export default class FacebookObjective extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** This is a disabled object */
        disabled: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        onMutation: () => {}
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value ? props.value : 'REACH'
        };

        // All objectives. Disable unavailable for now.
        this.objectives = {
            OUTCOME_AWARENESS: 'Awareness',
            OUTCOME_TRAFFIC: 'Traffic',
            OUTCOME_SALES: 'Sales',
            OUTCOME_ENGAGEMENT: 'Engagement',
            OUTCOME_APP_PROMOTION: 'App promotion'
        };
    }

    componentDidMount() {}

    /**
     * Set value
     * @param {*} item
     */
    handleChange = (event) => {
        this.setState({ value: event.target.value });
        this.props.onMutation(event.target.value);
    };

    render() {
        const { value = {} } = this.state;

        return (
            <div className="input__facebook-objective">
                <Select native value={value} onChange={this.handleChange} variant="outlined" fullWidth margin="dense">
                    {Object.keys(this.objectives).map((key, index) => (
                        <option value={key} key={index}>
                            {this.objectives[key]}
                        </option>
                    ))}
                </Select>
            </div>
        );
    }
}
