import '../styles/main.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from 'components/ui-components-v2/TextField';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Select from 'components/ui-components-v2/Select';

/**
 * Facebook Location
 * This displays the field for finding the Facebook locations for advertising on a location specific base.
 */
export default class FacebookCampaignBudget extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** This is a disabled object */
        disabled: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        onMutation: () => {}
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
                ? props.value
                : {
                      type: 'lifetime_budget',
                      budget: 10
                  }
        };
    }

    /**
     * Set type
     * @param {*} event
     */
    handleChangeType = (event) => {
        const value = this.state.value;
        value.type = event.target.value;
        this.setState({ value: value });
        this.props.onMutation(value);
    };

    /**
     * Set value
     * @param {*} event
     */
    handleChangeValue = (event) => {
        const value = this.state.value;
        value.budget = event.target.value;
        this.setState({ value: value });
        this.props.onMutation(value);
    };

    render() {
        const { value = {} } = this.state;

        return (
            <div className="input__facebook-campaign-budget">
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item alignItems="flex-end">
                        <FormControl>
                            <InputLabel id="facebook-campaign-budget-select-small">Type</InputLabel>
                            <Select
                                labelId="facebook-campaign-budget-select-small"
                                id="facebook-campaign-budget-select-small"
                                label="Type"
                                value={value.type}
                                onChange={this.handleChangeType}
                                variant="outlined"
                                fullWidth
                                margin="dense">
                                <MenuItem value="lifetime_budget">Lifetime budget</MenuItem>
                                <MenuItem value="daily_budget">Daily budget</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item alignItems="flex-end">
                        <TextField
                            label="Budget"
                            type="number"
                            variant="outlined"
                            margin="none"
                            InputProps={{ inputProps: { min: 10, max: 1000 } }}
                            value={value.budget}
                            onChange={this.handleChangeValue}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}
