import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from 'components/ui-components-v2/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Translation from 'components/data/Translation';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Button from 'components/ui-components-v2/Button';
import { FeedSelectorDialog } from 'components/feed-management/FeedSelectorDialog';
import { DataCard } from 'components/feed-management/DataCard';
import DataItem from 'components/feed-management/DataItem';
import { FeedSelector } from 'components/data/FeedManagementHelpers';
import '../styles/main.scss';

/**
 * FeedSelectorInput
 * This field is used to select one item from a feed. It shows a dialog.
 */
class FeedSelectorInput extends React.Component {
    static propTypes = {
        mapping: PropTypes.object,
        useObject: PropTypes.bool
    };

    static defaultProps = {
        mapping: null,
        useLanguage: [],
        onMutation: () => { }
    };

    constructor() {
        super();

        this.state = {
            feedSelectorDialogOpen: false
        };
    }

    /**
     * This close the dialog and writes the data to the parent onMutation element.
     */
    onMutation = (item, type = 'model') => {
        const { mapping, language, useLanguage, useObject } = this.props;
        const { mappedData, missingData } = FeedSelector.getMappedData(mapping, item, language, useLanguage, useObject);

        if (missingData.length > 0) {
            SnackbarUtils.warning(
                Translation.get('feedSelectorDialog.missingFeedItems', 'feed-management', {
                    missingItems: missingData.join(', ').replace(/, ([^,]*)$/, ' and $1')
                })
            );
        }
        this.setState({ feedSelectorDialogOpen: false });

        // If there is a mapping, use that item, otherwise return the whole object
        this.props.onMutation(mappedData ? mappedData : { _id: item._id, ...item.data, originalRecord: item }, type);
    };

    editItem = () => {
        this.setState({ feedSelectorDialogOpen: true });
    };

    /**
     * Remove a specific value
     */
    removeItem = (id, event) => {
        this.props.onMutation({});
        event && event.stopPropagation();
    };

    render() {
        const { feedSelectorDialogOpen } = this.state;
        const { value, compact, datacardMapping } = this.props;

        return (
            <div className="feed-selector-input">
                <div className="feed-selector-input__asset-wrapper">
                    {(!value || (value && !value.originalRecord)) && (
                        <Button onClick={() => this.setState({ feedSelectorDialogOpen: true })} variant="outlined">
                            Add feed item
                        </Button>
                    )}
                    {value && value.originalRecord && !compact && (
                        <React.Fragment>
                            <div className="feed-selector-input__asset-wrapper__data-card">
                                <DataCard
                                    onClickImage={() => this.editItem()}
                                    item={value.originalRecord ? value.originalRecord : value}
                                    mapping={FeedSelector.getDataCardMapping(value, datacardMapping)}
                                />
                            </div>
                            <div className="feed-selector-input__asset-wrapper__actions">
                                <Tooltip title="Edit item">
                                    <IconButton onClick={() => this.editItem()} size="small">
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Remove item">
                                    <IconButton onClick={() => this.removeItem()} size="small">
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </React.Fragment>
                    )}
                    {value && value.originalRecord && !!compact && (
                        <DataItem
                            id={FeedSelector.getItemId(value)}
                            key={FeedSelector.getItemId(value)}
                            data={FeedSelector.getItemData(value)}
                            mapping={FeedSelector.getDataCardMapping(value, datacardMapping)}
                            onRemoveItem={this.removeItem}
                            onEditItem={this.editItem}
                            className="feed-selector-input__asset-wrapper__item"
                        />
                    )}
                </div>

                {feedSelectorDialogOpen && (
                    <FeedSelectorDialog
                        {...this.props}
                        open={feedSelectorDialogOpen}
                        onClose={() => this.setState({ feedSelectorDialogOpen: false })}
                        onMutation={this.onMutation}
                    />
                )}
            </div>
        );
    }
}

export default FeedSelectorInput;
