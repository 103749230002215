import './../styles/main.scss';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Icon from 'components/ui-components-v2/Icon';

/**
 * FieldSetLabel
 * This displays the label in the fieldset.
 */
export default class FieldSetLabel extends Component {
    static propTypes = {
        /** Text to display above the component */
        label: PropTypes.string,
        /** Tooltip text */
        tooltip: PropTypes.string,
        /** Placement for the tooltip, possible values: 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top' */
        placement: PropTypes.string
    };

    static defaultProps = {
        label: '',
        tooltip: '',
        placement: 'bottom'
    };

    render() {
        const { label, tooltip, placement } = this.props;
        const hasTooltip = tooltip && tooltip !== '';

        return (
            <div className="input-field-set__label">
                <span>
                    {label}
                    {hasTooltip && (
                        <Tooltip title={tooltip} placement={placement}>
                            <Icon className="input-field-set__label__tooltip" fontSize="inherit">
                                info
                            </Icon>
                        </Tooltip>
                    )}
                </span>
            </div>
        );
    }
}
