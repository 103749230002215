import '../styles/main.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Axios from 'axios';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from 'components/ui-components-v2/Dialog';
import TextField from 'components/ui-components-v2/TextField';
import Slider from 'components/ui-components-v2/Slider';
import Button from 'components/ui-components-v2/Button';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Request from 'components/data/Request';

/**
 * Facebook Location
 * This displays the field for finding the Facebook locations for advertising on a location specific base.
 */
export default class FacebookLocation extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** This is a disabled object */
        disabled: PropTypes.bool,
        /** Location types */
        locationTypes: PropTypes.array
    };

    static defaultProps = {
        disabled: false,
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : [],
            locationTypes: props.locationTypes ? props.locationTypes : ['country', 'region', 'city', 'zip', 'place', 'geo_market']
        };
    }

    componentDidMount() {
        // Fetch a token to connect to the publish api
        Request.post('publishing/getToken', { type: 'metadata' }).then((result) => {
            if (!result.success) {
                SnackbarUtils.error("We coulnd't connect you to the Facebook API.");
                return;
            }
            this.token = result.data.token;
        });
    }

    /**
     * Change input, this starts a timeout for fetching the data
     * @param {*} event
     */
    handleSearch = (event) => {
        this.searchTerm = event.target.value;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.requestList, 400);
    };

    /**
     * Request the result list from Facebook
     */
    requestList = () => {
        if (this.searchTerm == '') {
            this.setState({ searchResults: [] });
            return;
        }

        Axios.post(process.env.PUBLISH_ENGINE + 'V2/metadata/facebookmarketing/search', {
            token: this.token,
            q: this.searchTerm,
            location_types: this.state.locationTypes
        }).then((response) => {
            if (response.data.data && response.data.data.data) {
                this.setState({ searchResults: response.data.data.data });
            }
        });
    };

    /**
     * Get text highlights
     */
    getHighlightedText = (country, title, highlight = '') => {
        let text = '';
        if (country) {
            text = country + ': ';
        }
        if (title) {
            text = text + title;
        }

        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {' '}
                {parts.map((part, i) => (
                    <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
                        {part}
                    </span>
                ))}{' '}
            </span>
        );
    };

    /**
     * Add a location
     * @param {*} item
     */
    handleLocationClick = (item) => {
        const value = this.state.value;
        if (item.type === 'city') {
            item.radius = 17;
        }
        if (item.type === 'place') {
            item.radius = 0;
        }

        value.push(item);
        this.setState({ value, searchResults: [] });
        this.props.onMutation(value);
    };

    /**
     * Handle remove
     * @param {*} index
     */
    handleRemove = (index) => {
        const value = this.state.value;
        value.splice(index, 1);
        this.setState({ value, searchResults: [] });
        this.props.onMutation(value);
    };

    /**
     * Handle radius
     * @param {*} radius
     * @param {*} index
     */
    handleRadius = (radius, index) => {
        const value = this.state.value;
        value[index].radius = parseInt(radius);

        this.setState({
            value: value,
            dialogRadius: value[index].radius
        });

        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(this.updateData, 400);
    };

    updateData = () => {
        this.props.onMutation(this.state.value);
    };

    dialogOpen = (index) => {
        const { value } = this.state;
        const item = value[index];

        if (item.type == 'city' || item.type == 'zip') {
            this.setState({ dialogOpen: true, dialogIndex: index, dialogRadius: item.radius });
        }
    };

    dialogClose = () => {
        this.setState({ dialogOpen: false });
    };

    dialogCloseEmpty = () => {
        const { dialogIndex } = this.state;
        const value = this.state.value;
        value[dialogIndex].radius = 0;

        this.setState({ dialogOpen: false, value });
    };

    render() {
        const { value = [], searchResults, dialogOpen, dialogRadius, dialogIndex } = this.state;

        return (
            <div className="input__facebook-location">
                <div className="input__facebook-location__input">
                    <TextField onChange={this.handleSearch} style={{ marginTop: 0, width: '100%' }} margin="dense" variant="outlined" />
                </div>
                {searchResults && searchResults.map && (
                    <div className="input__facebook-location__results">
                        {searchResults &&
                            searchResults.map((item, i) => (
                                <div className="input__facebook-location__results__item" onClick={() => this.handleLocationClick(item)} key={'res' + i}>
                                    <div className="input__facebook-location__results__item__name">
                                        {this.getHighlightedText(item.country_name, item.name, this.searchTerm)}
                                    </div>

                                    <div className="input__facebook-location__results__item__type">{item.type}</div>
                                </div>
                            ))}
                    </div>
                )}

                {value && value.length > 0 && (
                    <div className="input__facebook-location__list">
                        <div className="input__facebook-location__list__title">Selected locations:</div>

                        {value &&
                            value.map((item, i) => (
                                <div className="input__facebook-location__list__item" key={'list' + i}>
                                    <div className="input__facebook-location__list__item__name" onClick={() => this.dialogOpen(i)}>
                                        {this.getHighlightedText(item.country_name, item.name, this.searchTerm)}
                                        <div className="input__facebook-location__list__item__type">
                                            {item.type}

                                            {item.radius > 0 && <span>(+{item.radius} km)</span>}
                                        </div>
                                    </div>
                                    <div className="input__facebook-location__list__item__remove" onClick={() => this.handleRemove(i)}>
                                        <IconButton size="small">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}

                {dialogOpen && (
                    <Dialog open={true} onClose={this.dialogClose} maxWidth="sm" fullWidth={true}>
                        <DialogTitle id="customized-dialog-title" onClose={this.dialogClose}>
                            Select radius
                        </DialogTitle>
                        <DialogContent dividers>
                            <div className="input__facebook-location__list__item__distance">
                                <Button variant="outlined" onClick={this.dialogCloseEmpty} style={{ width: '100%', marginBottom: 20 }}>
                                    Only current location
                                </Button>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs>
                                        <Slider
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={80}
                                            step={1}
                                            marks={false}
                                            value={dialogRadius}
                                            onChange={(event, value) => this.handleRadius(value, dialogIndex)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Input
                                            value={dialogRadius}
                                            margin="dense"
                                            onChange={(event) => this.handleRadius(event.target.value, dialogIndex)}
                                            inputProps={{
                                                min: 0,
                                                max: 80,
                                                type: 'number'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <div>KM</div>
                                    </Grid>
                                </Grid>
                                <Button variant="outlined" onClick={this.dialogClose} style={{ width: '100%', marginTop: 20 }}>
                                    Close location
                                </Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                )}
            </div>
        );
    }
}
