import { DynamicValueOption } from '../types/dynamic-value.type';

export const DEFAULT_OPTIONS: DynamicValueOption[] = [
    { label: 'Campaign: Title', value: 'campaign.title' },
    { label: 'Campaign: Market', value: 'campaign.market' },
    { label: 'Campaign: Brand', value: 'campaign.brand' },
    { label: 'Campaign: Origin', value: 'campaign.origin' },
    { label: 'Campaign: Flight', value: 'campaign.flight' },
    { label: 'Campaign: Language', value: 'campaign.language' }
];
