import React, { useMemo, useState } from 'react';
import '../styles/main.scss';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import EditorData from 'components/editor-data/EditorData';
import Dialog from './dialog';
import { DEFAULT_OPTIONS } from '../constants/default-options';
import { DynamicValueOption } from '../types/dynamic-value.type';
import { DynamicDataHelper } from '../helpers/dynamic-data-helper';

interface Props {
    /** Function which gets called when the switch has changed */
    onMutation: (value: string) => void;
    /** Current value of the picker */
    value?: string;
    /** Dynamic value options */
    dynamicValueOptions: DynamicValueOption[];
    additionalVars: any;
}

const DynamicData: React.FC<Props> = ({ onMutation, value, dynamicValueOptions = [], additionalVars }) => {
    const expectedValue = useMemo(() => {
        if (!value) return '';
        return DynamicDataHelper.getDynamicValue(value ? value : '', additionalVars);
    }, [value]);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const fieldLabel = useMemo(() => {
        const actualValue = value ? value?.replace('%[', '').replace(']%', '') : '';
        if (!actualValue.length) return 'No value assigned to the input';
        return actualValue;
    }, [value]);

    /**
     * Change the input of the switch, so set state and mutate.
     */
    const onChange = (value: string) => {
        onMutation(value);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <div className="input__dynamic-data">
            <div className="input__dynamic-data__field" onClick={handleDialogOpen}>
                <div className="input__dynamic-data__field__content input__dynamic-data__field__content--custom">
                    <div className="input__dynamic-data__field__content__icon">
                        <Icon>code</Icon>
                    </div>
                    <div className="input__dynamic-data__field__content__label">
                        {Translation.get('input.dynamicData.custom', 'common')}: <span>{fieldLabel}</span>
                    </div>
                </div>
            </div>
            {dialogOpen && (
                <Dialog
                    open={true}
                    onClose={handleDialogClose}
                    onChange={onChange}
                    value={value}
                    options={[...DEFAULT_OPTIONS, ...dynamicValueOptions]}></Dialog>
            )}
            <div className="input__dynamic-data__expected-output">
                <span className="input__dynamic-data__expected-output__copy">Expected value: </span>
                <span className="input__dynamic-data__expected-output__value">{expectedValue}</span>
            </div>
        </div>
    );
};

export default DynamicData;
