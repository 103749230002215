import React, { Component, Suspense } from 'react';
const RangePicker = React.lazy(() => import('./lazy'));

export class rangePickerLazy extends Component {
    render() {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <RangePicker {...this.props} />
            </Suspense>
        );
    }
}
export default rangePickerLazy;
