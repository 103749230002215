import DecisionTreeOptions from './component';
import { FieldSetWrapper } from '../FieldSet';


const FieldSetDecisionTreeOptions = FieldSetWrapper(DecisionTreeOptions);

export default DecisionTreeOptions;
export {
    DecisionTreeOptions,
    FieldSetDecisionTreeOptions
}
