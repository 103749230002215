import React from 'react';
import FieldSetDynamic from './dynamic';
import FieldSet from './regular';

/**
 * FieldSetWrapper
 * This components wraps the FieldSet component around the passed component
 * @param {*} WrappedComponent
 */
function FieldSetWrapper(WrappedComponent) {
    class FieldSetDecorator extends React.Component {
        static defaultProps = {
            ...FieldSet.defaultProps,
            ...WrappedComponent.defaultProps
        };

        render() {
            const {
                alignLeft,
                label,
                tooltip,
                tooltipPlacement,
                error,
                fullWidth = false,
                helperText,
                tourData,
                hidden,
                dynamicValueActive,
                value,
                onOverwriteReset,
                extraLabelText,
                actions,
                onMutation,
                defaultValue
            } = this.props;

            // This input field can contain dynamic values
            if (dynamicValueActive) {
                return (
                    <FieldSetDynamic
                        hidden={hidden}
                        label={label}
                        error={error}
                        tooltip={tooltip}
                        tooltipPlacement={tooltipPlacement}
                        alignLeft={alignLeft}
                        fullWidth={fullWidth}
                        tourData={tourData}
                        helperText={helperText}
                        value={value}
                        defaultValue={defaultValue}>
                        <WrappedComponent {...this.props} />
                    </FieldSetDynamic>
                );
            }
            // We have a standard field
            else {
                return (
                    <FieldSet
                        hidden={hidden}
                        label={label}
                        error={error}
                        tooltip={tooltip}
                        tooltipPlacement={tooltipPlacement}
                        alignLeft={alignLeft}
                        fullWidth={fullWidth}
                        tourData={tourData}
                        helperText={helperText}
                        onOverwriteReset={onOverwriteReset}
                        extraLabelText={extraLabelText}
                        actions={actions}
                        value={value}
                        onMutation={onMutation}
                        {...this.props}>
                        <WrappedComponent {...this.props} />
                    </FieldSet>
                );
            }
        }
    }

    return FieldSetDecorator;
}

export default FieldSetWrapper;
