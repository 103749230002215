import React, { useState, useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import ColorPicker from 'components/ui-components/ColorPicker';
import getColorPickerObject from 'components/input/ColorSelector/utils/getColorPickerObject';
import cloneDeep from 'helpers/cloneDeep';
import Translation from 'components/data/Translation';
import Color, { BrandGuideColor } from 'components/template-designer/types/color.type';
import '../styles/main.scss';

interface Props {
    value: (string | BrandGuideColor)[];
    useGradients?: boolean;
    presetColors?: (string | BrandGuideColor)[];
    defaultValue?: (string | BrandGuideColor)[];
    disableFirst?: boolean;
    onMutation: (value: (string | BrandGuideColor)[]) => void;
    onBlur?: () => void;
    maxColors?: number;
}

const DynamicColorList = ({ value, defaultValue, useGradients, presetColors, disableFirst, onMutation, onBlur, maxColors = 15 }: Props) => {
    const getDefaultValue = () => {
        if (!defaultValue) return [];
        if (useGradients === false) {
            return defaultValue
                .filter((color) => typeof color === 'object' && !color.gradientColor)
                .map((color) => (typeof color === 'object' && color.color ? getColorPickerObject(color.color) : color));
        } else {
            return defaultValue.map((color) => (typeof color === 'object' && color.color ? getColorPickerObject(color.color) : color));
        }
    };

    const [colors, setColors] = useState(value || getDefaultValue());

    // Sync the value from the parent component
    useEffect(() => {
        if (value && value !== colors) {
            setColors(value);
        }
    }, [value]);

    //If the default value is used send it to the component above.
    useEffect(() => {
        if (!value) {
            onMutation(getDefaultValue());
        }
    }, []);

    /**
     * Changes the color
     * @param index Index of the color
     * @param value The value of the color
     */
    const onColorInput = (index: number, value: string | Color) => {
        const newColors = cloneDeep(colors);

        if (typeof value === 'string') {
            newColors[index] = value;
        } else {
            newColors[index] = value;
        }

        if (isEqual(colors, newColors)) return;
        setColors(newColors);
        onMutation(newColors);
    };

    /**
     * Removes a color
     * @param index Index of the color
     */
    const removeColor = (index: number) => {
        const newColors = cloneDeep(colors);
        newColors.splice(index, 1);

        setColors(newColors);
        onMutation(newColors);
    };

    /**
     * Adds a color
     */
    const addColor = () => {
        const newColors = cloneDeep(colors);
        newColors.push(getColorPickerObject('#ffffffff'));

        setColors(newColors);
        onMutation(newColors);
    };

    /**
     * Gets the preset colors and filters them based on the useGradients prop
     */
    const brandGuideColors = useMemo(() => {
        if (!presetColors) return [];
        if (useGradients === false) return presetColors.filter((color) => typeof color === 'object' && !color.gradientColor);
        return presetColors;
    }, [useGradients, presetColors]);

    return (
        <div className="input__dynamic-color-list" onBlur={onBlur}>
            {colors.map((color, i) => {
                const correctColor = (() => {
                    if (typeof color === 'string') {
                        return getColorPickerObject(color);
                    }

                    return color;
                })();

                const isDisabled = disableFirst && i === 0;

                return (
                    <div key={i} className="input__dynamic-color-list__color">
                        <ColorPicker
                            color={correctColor}
                            useGradients={useGradients}
                            onChange={(value) => onColorInput(i, value)}
                            presetColors={brandGuideColors}
                            getPresetColors={undefined}
                            disabled={isDisabled}
                        />
                        <IconButton
                            onClick={() => removeColor(i)}
                            disabled={isDisabled}
                            style={{ opacity: isDisabled ? 0 : 1 }}
                            size="small"
                            className="delete-color">
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                );
            })}

            {colors.length < maxColors && (
                <Button className="input__dynamic-color-list__add-color" onClick={addColor} color="primary" size="small" variant="outlined">
                    {Translation.get('input.dynamicColorList.addColor', 'common')}
                </Button>
            )}
        </div>
    );
};

export default DynamicColorList;
