import './../styles/main.scss';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Switch from 'components/ui-components-v2/Switch';
import InlineAlert from 'components/ui-components/InlineAlert';
import Typo from 'components/ui-base/Typo';
import DynamicData from 'components/input/DynamicData/components/index';
import FieldSetLabel from './label';

/**
 * FieldSet
 * This is a wrapper around the input fields that allows you to display a label and tooltip.
 * In this case, it's the dynamic fieldset that is used for dynamic data when connecting to a feed.
 */
export default class FieldSetDynamic extends Component {
    static propTypes = {
        alignLeft: PropTypes.bool,
        children: PropTypes.node,
        /** Label to display above the component */
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        /** Settings this adds a ( i ) icon next to the label which displays this text when hoovered. To customize the placement, set tooltipPlacement. */
        tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        /** Placement for the tooltip, possible values: 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top' */
        tooltipPlacement: PropTypes.string,
        /** Error */
        error: PropTypes.string,
        fullWidth: PropTypes.bool,
        defaultValue: PropTypes.string
    };

    static defaultProps = {
        alignLeft: false,
        label: '',
        tooltip: '',
        tooltipPlacement: 'bottom'
    };

    onChangeType = (e) => {
        const { onMutation, defaultValue } = this.props.children.props;
        if (e.target.checked) {
            onMutation('%[]%');
        } else {
            onMutation(defaultValue);
        }
    };

    render() {
        const {
            alignLeft,
            children,
            label,
            tooltip,
            tooltipPlacement,
            compact = false,
            error,
            fullWidth = false,
            helperText,
            hidden = false,
            value
        } = this.props;

        let style = {};
        if (compact) {
            style = { width: '50%', display: 'inline-block' };
        } else if (hidden) {
            style = { display: 'none' };
        }

        const isDynamic = value && value.substring && value.substring(0, 2) === '%[' ? true : false;

        return (
            <div className={'input-field-set input-field-set--dynamic ' + (fullWidth ? 'input-field-set--full-width' : '')} style={style}>
                <div className="input-field-set__header">
                    <div className="input-field-set__header__left">
                        <FieldSetLabel label={label} tooltip={tooltip} placement={tooltipPlacement} />
                    </div>
                    <div className="input-field-set__header__right">
                        <div>Dynamic field</div>
                        <Switch size="small" checked={isDynamic} onClick={this.onChangeType} />
                    </div>
                </div>

                {error && <InlineAlert>{error}</InlineAlert>}
                <div className={`input-field-set__fields ${alignLeft ? 'left' : ''}`}>
                    {isDynamic ? <DynamicData {...children.props} value={value} /> : children}
                </div>
                {helperText && (
                    <div className="input-field-set__helper-text">
                        <Typo variant="caption" color="secondary">
                            {helperText}
                        </Typo>
                    </div>
                )}
            </div>
        );
    }
}
