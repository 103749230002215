import '../styles/main.scss';
import PropTypes from 'prop-types';
import React from 'react';
import FeedSelectorDialog from 'components/feed-management/FeedSelectorDialog';
import DataItem from 'components/feed-management/DataItem';
import Button from 'components/ui-components-v2/Button';
import { FeedSelector } from 'components/data/FeedManagementHelpers';
import Icon from 'components/ui-components-v2/Icon';

/**
 * FeedMultiSelectorInput
 * This allows to add one or more items from the feed and return this as the output of this component in the model.
 */
class FeedMultiSelectorInput extends React.Component {
    static propTypes = {
        mapping: PropTypes.object
    };

    static defaultProps = {
        mapping: null,
        useLanguage: [],
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            feedSelectorDialogOpen: false
        };
    }

    /**
     * Handle mutations from the FeedSelectorDialog
     * @param {object} data
     * @param {string} type
     */
    onMutation = (data, type = 'model') => {
        const { value } = this.props;

        if (value && Array.isArray(value) && value.findIndex((x) => x._id === data._id) > -1) {
            // Item was allready selected, so remove;
            this.removeItem(data._id, type);
        } else {
            this.addItem(data, type);
        }
    };

    /**
     * Remove a specific value
     * @param {string} id
     * @param {string} type
     */
    removeItem = (id, type = 'model') => {
        const { value } = this.props;
        const index = value.findIndex((item) => (item.originalRecord ? item.originalRecord._id === id : item._id === id));
        value.splice(index, 1);
        this.props.onMutation(value, type);
    };

    /**
     * Add new item to the dataset
     * @param {*} data
     * @param {*} type
     */
    addItem = (item, type = 'model') => {
        const { mapping, language, useLanguage, value } = this.props;
        const { mappedData } = FeedSelector.getMappedData(mapping, item, language, useLanguage);

        // If there is a mapping, use that data, otherwise return the whole object
        const newItem = mappedData ? mappedData : { _id: item._id, ...item.data, originalRecord: item };

        let newValue;
        if (value && Array.isArray(value)) {
            newValue = [...value, newItem];
        } else {
            newValue = [newItem];
        }

        this.props.onMutation(newValue, type);
    };

    render() {
        const { feedSelectorDialogOpen } = this.state;
        const { value, datacardMapping } = this.props;

        return (
            <div className="feed-multi-selector-input">
                <div className="feed-multi-selector-input__asset-wrapper">
                    {Array.isArray(value) &&
                        value.length > 0 &&
                        value.map((item) => (
                            <DataItem
                                id={FeedSelector.getItemId(item)}
                                key={FeedSelector.getItemId(item)}
                                data={FeedSelector.getItemData(item)}
                                mapping={FeedSelector.getDataCardMapping(item, datacardMapping)}
                                onRemoveItem={this.removeItem}
                                className="feed-multi-selector-input__item"
                            />
                        ))}
                    <Button variant="contained" fullWidth onClick={() => this.setState({ feedSelectorDialogOpen: true })} startIcon={<Icon>add</Icon>}>
                        Add items
                    </Button>
                </div>

                {feedSelectorDialogOpen && (
                    <FeedSelectorDialog
                        {...this.props}
                        open={feedSelectorDialogOpen}
                        onClose={() => this.setState({ feedSelectorDialogOpen: false })}
                        onMutation={this.onMutation}
                    />
                )}
            </div>
        );
    }
}

export default FeedMultiSelectorInput;
